// @ts-nocheck
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  desktopBreakpoint,
  colors,
  desktopVW,
  mobileVW,
  mobileContentSidePadding,
  zIndex,
} from '../../styles/index';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import paper from '../../../static/paperBackground/paper.jpeg';
import Freckles from '../shared/Freckles';
import Img from 'gatsby-image';
import ProductFormSection from './ProductFormSection';

interface Props {
  title: string;
  oversell: boolean;
  fastShipping: boolean;
  allowAddProtection: boolean;
  shopifyData: {
    price: string;
    compareAtPrice: string;
    outOfStock: boolean;
  };
  productImages: [
    {
      title: string;
      fluid: string;
    }
  ];
  variantsImages: [
    {
      variantTitle: string;
      images: [
        {
          title: string;
          fluid: string;
        }
      ];
    }
  ];
  productCategory: {
    category: string;
    subcategory: string;
  };
  description: {
    json: string;
  };
  specs: {
    json: string;
  };
  materials: {
    json: string;
  };
  selectedVariant: {
    price: string;
    title: string;
    outOfStock: string;
    compare_at_price: string;
  };
  setSelectedVariantState: any;
  updateVariants: any;
  productTag: string;
  protectionProduct: any;
  scrollToReviews: any;
  starRating: number;
  thumbnailVariantsImages: any;
  variantItemForOption: any;
  titleVariantItem: string;
  titleProtectionVariantItem: string;
}

export default function ProductMainMobile(props: Props) {
  const {
    title,
    oversell,
    fastShipping,
    allowAddProtection,
    shopifyData,
    productImages,
    variantsImages,
    productCategory,
    description,
    specs,
    materials,
    selectedVariant,
    setSelectedVariantState,
    updateVariants,
    productTag,
    protectionProduct,
    scrollToReviews,
    starRating,
    shopifyVariants = { shopifyVariants },
    thumbnailVariantsImages,
    variantItemForOption,
    titleVariantItem,
    titleProtectionVariantItem,
  } = props;

  const params = {
    slidesPerView: 1,
    spaceBetween: 0,
    grabCursor: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: false,
    },
    shouldSwiperUpdate: true,
  };
  const [images, setImages] = useState([]);

  const selectedVariantImages = variantsImages?.find(
    images => images?.variantTitle === selectedVariant?.title
  );
  const [imageFromOption, setImageFromOption] = useState(null);
  const imageFromVariantItem = (e: any) => {
    setImageFromOption(e?.productImages);
  };

  function removeDuplicatesByTitle(data) {
    if (data) {
      const seenTitles = new Set();
      let filter = data?.filter(item => {
        if (seenTitles?.has(item?.title)) {
          return false;
        } else {
          seenTitles.add(item?.title);
          return true;
        }
      });

      return filter?.filter(item => item != null);
    } else {
      return productImages;
    }
  }
  useEffect(() => {
    let combinedArray = selectedVariantImages?.images.concat(productImages);
    if (imageFromOption) {
      combinedArray = imageFromOption;
    }
    setImages(combinedArray);
  }, [selectedVariantImages, imageFromOption]);

  return (
    <Wrapper>
      <FrecklesWrapper>
        <Freckles canvasHeight={'150vh'} />
      </FrecklesWrapper>
      <SwiperWrapper className="product-slider-wrapper">
        <Swiper {...params}>
          {removeDuplicatesByTitle(images)?.map((item, index) => (
            <div className="swiper-slide" key={item?.title}>
              <SwiperSlide>
                <ImageWrapper>
                  <StyledImg fluid={item?.fluid} />
                </ImageWrapper>
              </SwiperSlide>
            </div>
          ))}
        </Swiper>
      </SwiperWrapper>
      <ProductFormWrapper>
        <ProductFormSection
          shopifyData={shopifyData}
          title={title}
          description={description}
          specs={specs}
          materials={materials}
          selectedVariant={selectedVariant}
          setSelectedVariantState={setSelectedVariantState}
          oversell={oversell}
          fastShipping={fastShipping}
          allowAddProtection={allowAddProtection}
          updateVariants={updateVariants}
          productCategory={productCategory}
          productTag={productTag}
          protectionProduct={protectionProduct}
          scrollToReviews={scrollToReviews}
          starRating={starRating}
          shopifyVariants={shopifyVariants}
          thumbnailVariantsImages={thumbnailVariantsImages}
          variantItemForOption={variantItemForOption}
          imageFromVariantItem={(e: any) => imageFromVariantItem(e)}
          titleVariantItem={titleVariantItem}
          titleProtectionVariantItem={titleProtectionVariantItem}
        />
      </ProductFormWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url(${paper});
  background-repeat: repeat;
`;

const SwiperWrapper = styled.div`
  position: relative;
  width: 100vw;
  overflow: hidden;
  z-index: ${zIndex.surface};

  .swiper-container {
    width: 100%;
  }
  .swiper-wrapper {
    //align-items: left;
  }

  .swiper-pagination {
    margin-bottom: ${mobileVW(20)};
    position: absolute;
    top: ${mobileVW(410)};
    left: 50vw;
    transform: translateX(-50%);
  }
  .swiper-pagination-bullet {
    background-color: black;
    transform: scale(1);
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    transform: scale(1.3);
  }
`;

const SwiperSlide = styled.div`
  //min-height: ${mobileVW(1200)};
  height: auto;


`;

const FrecklesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndex.dots};
`;

const ImageWrapper = styled.div`
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  height: ${mobileVW(450)};
  z-index: ${zIndex.backgrounds};
`;

const StyledImg = styled(Img)`
  width: 100vw;
`;

const ProductFormWrapper = styled.div`
  /* position: absolute;
  top: ${mobileVW(450)};
  left: 0; */
  width: auto;
  z-index: ${zIndex.surface};
  padding: 0 ${mobileContentSidePadding};

`;
